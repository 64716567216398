<template>
  <div class="mx-auto max-w-sm">
      <input @change="getHistorico" v-show="usuarioTest()" type="date" class="w-full" v-model="data">
      
      <div class="grid grid-cols-3 md:mx-4">
        <div class="my-2 text-left" v-if="!fit">
          <p class="text-center text-lg">Proteínas</p> <p class="text-center text-xl ">{{( totais.p / userForm.peso ).toFixed(2) }}g/kg</p>
        </div> 
        <div class="my-2 text-left" v-if="fit">
            <p class="text-center text-lg">Kcal</p> <p class="text-center text-xl ">{{totais.kcal.toFixed(0)}}</p>
        </div> 

        <p class="text-2xl my-2 text-center" v-if="!fit">
          {{totais.cotas}} cotas
          <!--<button class="text-xs px-1 py-1 bg-blue-400 text-white rounded" @click="mensagemDoDia(true)">Mensagem do dia</button>-->
        </p> 
        <div class="text-2xl my-2 text-center" v-if="fit">
          <p class="text-center text-lg">Meta</p> 
          <p class="text-center text-xl" :style=" 'color:' + hsl_col_perc(getMetaNovo(), 0, 100) ">
            <b>{{getMetaNovo()}} %</b>
          </p>
          <!--<button v-if="!fit"  class="text-xs px-1 py-1 bg-blue-400 text-white rounded" @click="mensagemDoDia(true)">Mensagem do dia</button>-->
        </div> 
        
        
        <div class="my-2 text-right" v-if="!fit">
            <p class="text-center text-lg">Limite</p> <p class="text-center text-xl ">{{mmmRecord.toFixed(1)}}</p>
        </div> 
        <div class="my-2 text-right" v-if="fit">
            <p class="text-center text-lg">Proteínas</p> <p class="text-center text-xl ">{{totais.p1hora.toFixed(0)}}g / 30g</p>
        </div> 
      </div>
      
      <!--<div @click="collapseArea1 = !collapseArea1" class="p-2 border-2 border-gray-200 rounded-sm flex items-center justify-center mb-2">
        <span class=" font-bold">Adição refeição</span> 
        <svg v-if="!collapseArea1" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
        <svg v-if="collapseArea1" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </div>-->

      <div v-show="collapseArea1">
        <div class="mx-4 flex flex-col">
          <v-select :filterBy="filterBy" v-model="form.alimento" label="nomeBusca" :filterable="true" :options="alimentos" class="flex-1">
            <template slot="no-options">
              Digite para buscar
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center text-sm" v-if="fit">
               <!--<span  class="float-left -mt-1" v-html="getIndiceProt(option)"></span>{{ ((option.p * 2) - option.c).toFixed(2) }} - {{ option.nome }}-->
               <span  class="float-left -mt-1" v-html="option.icone"></span>{{ option.nome }}
              </div>
              <div class="d-center text-sm" v-if="!fit">
               {{ option.nome }} ({{ option.cota }} cota{{parseFloat(option.cota) > 1 ? 's':''}})
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </v-select>
          <button :disabled="!form.alimento" class="p-2 mt-2 px-4 mx-1 bg-green-400 text-white rounded" @click="comeu()">{{form.alimento ? `+1 ${form.alimento.medida}` : '+'}}</button>
        </div>

        <div class="text-center mx-4">
          <a class="text-red-700 text-xs block mt-4" href="#" @click="incluir = !incluir">Não encontrou o alimento?</a>

          <t-modal
            v-model="incluir"
            header="Solicitar inclusão de alimento"
          >
            <input v-model="nome" placeholder="Digite o nome do alimento" type="text" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            <template v-slot:footer>
              <div class="">
                <t-button @click="solicitarInclusao()" type="button" classes="px-4 py-2 mt-2 bg-green-400 text-white rounded">
                  Solicitar inclusão
                </t-button>
              </div>
            </template>
          </t-modal>


          <t-modal
            v-model="modalpesocintura"
            header="Hoje é dia de atualizar suas medidas"
          >
            <label for="peso">Peso em jejum, sem roupas</label>
            <input name="peso" v-model="userForm.peso" placeholder="Informe seu peso" type="number" step="0.01" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            
            <label for="cintura">Cintura</label>
            <input name="cintura" v-model="userForm.cintura" placeholder="Informe sua cintura" type="number" step="0.01" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />

            <p>Clique <a class="text-blue-500" href="https://www.clinicadometabolismo.com/cintura" target="_blank">aqui</a> para saber como medir corretamente sua cintura</p>

            <template v-slot:footer>
              <div class="">
                <t-button @click="atualizar()" type="button" classes="px-4 py-2 mt-2 bg-green-400 text-white rounded">
                  Atualizar
                </t-button>
              </div>
            </template>
          </t-modal>
          
          <t-modal
            v-model="modalstartday"
            header="Mensagem do dia"
            :classes="classes"
          >
            <p v-html="textomodalstartday"></p>
            <template v-slot:footer>
              <div v-if="mostrarReset">
                <t-button @click="reset()" type="button" classes="px-4 py-2 mt-2 bg-yellow-400 text-black rounded">
                  Reset
                </t-button>
              </div>
            </template>
          </t-modal>
        </div>
      
        <div class="flex flex-col m-4 mt-8" >
          <div class="overflow-x-auto">
            <div class="py-2 align-middle inline-block min-w-full max-w-sm">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg flex-grow">
                <table class="min-w-full divide-y divide-gray-200" style="background-color:rgba(0, 0, 0, 0);">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Alimento
                      </th>
                      <th scope="col" class="text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cotas
                      </th>
                      <th scope="col" class="text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        +/-
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200" style="background-color:rgba(0, 0, 0, 0);">
                    <tr v-for="(historico, idx) in historicos" :key="idx">

                      <td class="">
                        <div class="text-xs font-medium text-gray-900 ">
                          <span v-if="fit" class="float-left -mt-1" v-html="historico.icone"></span> {{ getFracao(historico.quantidade) }} {{traduzPlural(historico.quantidade, historico.medida)}} de {{ historico.nome }}
                        </div>
                      </td>

                      <td class="">
                        <div class="text-xs text-center text-gray-900">{{ historico.cota }}</div>
                      </td>
                      <td class="text-center">
                        <button @click="adicionar(historico._id)" class="bg-blue-400 text-white rounded px-2 mx-1">
                          <svg class="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                        </button>
                        <button @click="remover(historico._id)" class="bg-blue-400 text-white rounded px-2 mx-1">
                          <svg class="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M18 12H6" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!--<div class="p-2">
        <button type="button" @click="collapseArea2 = !collapseArea2" class="max-w-md w-full p-2 border-2 border-gray-200 rounded-sm flex items-center justify-center mt-2">
          <svg v-if="!collapseArea2" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        
          <svg v-if="collapseArea2" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
          </svg>
        </button>
      </div>-->


      <div v-show="collapseArea2">        
        <div class="mt-2 p-2">
            <p class="text-center mb-8">
              Médias nutricionais <br>
              <button v-if="!fit" @click="reset" class="-mt-1 px-2 text-sm mr-4 mx-1 bg-gray-400 text-white rounded" id="reset" type="button">Reset</button>
            </p>
            <p class="text-center mb-8">
            </p>

            <table class="text-xs min-w-full divide-y divide-gray-200" v-if="dadosHoje.userForm">
              <tbody>
                <tr>
                  <td style="width: 33.33%;">Kcal</td>
                  <td style="width: 33.33%;" class="text-center" :class="{'bg-gray-200': !fit}">{{dadosHoje.mediaKcal ? dadosHoje.mediaKcal.toFixed(2) : 0}}</td>
                  <td style="width: 33.33%;" v-if="!fit"></td>
                </tr>

                <tr v-show="dadosHoje.userForm.tipoD === `oms`">
                  <td  >Carboidratos</td>
                  <td   class="text-center" :class="{'bg-red-300': !fit && (dadosHoje.percC.passou || dadosHoje.percC.faltou), 'bg-green-200': !fit && !dadosHoje.percC.passou && !dadosHoje.percC.faltou }">{{dadosHoje.mediaCarb ? dadosHoje.mediaCarb.toFixed(2) : 0}}g</td>
                  <td  v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesC"></td>
                </tr>
                <tr v-show="dadosHoje.userForm.tipoD !== `oms`">
                  <td>Carboidratos</td>
                  <td class="text-center" :class="{'bg-red-300': !fit && dadosHoje.mediaCarb > 50, 'bg-green-200': !fit && dadosHoje.mediaCarb <= 50 }">{{dadosHoje.mediaCarb ? dadosHoje.mediaCarb.toFixed(2): 0}}g</td>
                  <td v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesC"></td>
                </tr>


                <tr v-show="dadosHoje.userForm.tipoD === `oms`">
                  <td>Proteinas</td>
                  <td class="text-center" :class="getCorProt()">{{dadosHoje.mediaProt ? dadosHoje.mediaProt.toFixed(2) : 0}}g/kg</td>
                  <td v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesP"></td>
                </tr>
                <tr v-show="dadosHoje.userForm.tipoD !== `oms`">
                  <td>Proteinas</td>
                  <td class="text-center" :class="{'bg-red-300': !fit && dadosHoje.mediaProt < 1.5, 'bg-green-200': !fit && dadosHoje.mediaProt >= 1.5}">{{dadosHoje.mediaProt ? dadosHoje.mediaProt.toFixed(2) : 0}}g/kg</td>
                  <td v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesP"></td>
                </tr>

                <tr>
                  <td>Gorduras</td>
                  <td class="text-center" :class="{'bg-red-300': !fit && dadosHoje.percG.passou , 'bg-green-200': !fit && !dadosHoje.percG.passou }">{{dadosHoje.percG.perc.toFixed(2)}}%</td>
                  <td v-if="!fit" class="text-center max-w-12" v-html="dadosHoje.orientacoesG"></td>
                </tr>

                <tr>
                  <td>Gorduras Saturadas</td>
                  <td class="text-center" :class="{'bg-red-300': !fit && dadosHoje.percGS.passou , 'bg-green-200': !fit && !dadosHoje.percGS.passou }">{{dadosHoje.percGS.perc.toFixed(2)}}%</td>
                  <td v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesGS"></td>
                </tr>

                <tr>
                  <td>Sódio</td>
                  <td class="text-center" :class="{'bg-red-300': !fit && dadosHoje.mediaNA > 2000 , 'bg-green-200': !fit && dadosHoje.mediaNA < 2000}">{{dadosHoje.mediaNA ? dadosHoje.mediaNA.toFixed(2) : 0}}mg</td>
                  <td  v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesNA"></td>
                </tr>

                <tr>
                  <td>Fibras</td>
                  <td class="text-center" :class="{'bg-red-300': !fit && dadosHoje.mediaF < dadosHoje.fibraideal, 'bg-green-200': !fit && dadosHoje.mediaF >= dadosHoje.fibraideal }">{{dadosHoje.mediaF ? dadosHoje.mediaF.toFixed(2) : 0}}g</td>
                  <td v-if="!fit" class="text-center" v-html="dadosHoje.orientacoesF"></td>
                </tr>
              </tbody>
            </table>

           <div class="grid grid-cols-2 mt-4">
              <div class="mt-4 text-sm">
                <input @change="getHistorico" v-model="fit" id="fitness" type="checkbox" class="mb-1"> 
                <label for="fitness" class="ml-1 text-sm">Modo fitness</label>
              </div>
              <div class="mt-2 text-right">
                <a class="ml-20 text-sm" href="https://wa.me/554288182382" target="_blank" style="display: block;">
                  <span class="float-left" style="margin-top: 7px;">Dúvidas?</span>
                  <img src="https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-png-0-1536x1536.png" style="height: 30px;">
                </a>
              </div>
          </div>
              
        </div>
        <div class="p-2" v-show="!fit" >
          <Bar :styles="myStyles" :chart-data="chartBar" :options="options"></Bar>
        </div>
        <div class="p-2" v-show="fit && dadosHoje.mediaKcal" >
          <Bar :styles="myStyles" :chart-data="chartBarFit" :options="optionsFit"></Bar>
        </div>
        <hr>
      </div>
  </div>
</template>

<script>
import Bar from './Bar';
import moment from "moment";

// @ is an alias to /src
export default {
  components: {
    Bar,
  },
  name: 'Home',
  data() {
    return {
      myStyles: {
        height: '300px',
        position: 'relative',
        left: '-6px',
      },
      classes: {
        overlay: 'z-40 bg-black bg-opacity-50',
        wrapper: 'z-50 max-w-lg px-3 py-12',
        modal: 'bg-white shadow rounded',
        body: 'p-3',
        header: 'border-b border-gray-100 p-3 rounded-t font-bold',
        footer: 'bg-gray-100 p-3 rounded-b',
        close: 'bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      nome: '',
      collapseArea1: true,
      collapseArea2: true,
      incluir: false, 
      modalstartday: false, 
      modalpesocintura: false, 
      mostrarReset: false, 
      textomodalstartday: '',
      amenorindice: '',
      menorindice: 999999,
      amaiorindice: '',
      maiorindice: -999999,
      userForm: {},
      media: 0,
      alimentos:[],
      form: {
        alimento: null
      },
      totalCotas: 0,
      data: moment().format("YYYY-MM-DD"),
      fit: false, 
      historicos: [],
      historicos1hora: [],
      chartBar: {
        labels: ['carregando...', 'carregando...', 'carregando...'],
        datasets: [
          {
            label: 'carregando...',
            data: [0, 0, 0],
            borderColor: '#436fb5',
            backgroundColor: '#436fb5',
          },
        ]
      },
      options: {
        annotation: {
            annotations: [
              {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: 0,
                  borderColor: '#2196f3',
                  borderWidth: 2,
                  label: {
                    enabled: true,
                    backgroundColor: 'rgba(85,85,85,0)',
                    content: `Manhã 0 cotas`
                  }
              },
              {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: 0,
                  borderColor: '#ffeb3b',
                  borderWidth: 2,
                  label: {
                    enabled: true,
                    backgroundColor: 'rgba(85,85,85,0)',
                    content: `Tarde 0 cotas`
                  }
              },
              {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: 0,
                  borderColor: '#0d47a1',
                  borderWidth: 2,
                  label: {
                    enabled: true,
                    backgroundColor: 'rgba(85,85,85,0)',
                    content: `Noite 0 cotas`
                  }
              }
          ]
        },
        plugins: {
          datalabels: {
              display: false
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 10,
            }
          }],
          xAxes: [{
            ticks: {
                autoSkip: false,
            }
          }],
        }
      },
      chartBarFit: {
        labels: ['Proteína', 'Gordura', 'Carboidratos'],
        datasets: [
          {
            data: [0, 0, 0],
            borderColor: ['#66BB6A', '#FDD835', '#F44336'],
            backgroundColor: ['#66BB6A', '#FDD835', '#F44336'],
          },
        ]
      },
      optionsFit: {
        tooltips: {
            callbacks: {
              title: function() {}
            }
        },
        legend: {
          display: false
        },
        plugins: {
          datalabels: {
              display: true,
              anchor: 'end',
              align: 'end',
              formatter: function(value) {
                return parseFloat(value).toFixed(2) + "%";
              }
          }
        },
        scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepValue: 5,
                stepSize: 5,
                min: 0,
                max: 65,
              }
            }],
            xAxes: [{
              categoryPercentage: 1,
              barPercentage: 0.7,
              ticks: {
                  autoSkip: false,
              },
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
      },
      dados: {},
      dadosHoje: {},
      totais: {
        c: 0,
        p: 0,
        p1hora: 0,
        kcal: 0,
        cotas: 0
      },
      mmmRecord: 0,
    }
  },
  methods: {
    getCorProt() {
      console.log(this.dadosHoje.mediaProt)
      return {
        'bg-red-300': !this.fit && (this.dadosHoje.percP.passou || this.dadosHoje.percP.faltou || (this.dadosHoje.mediaProt < 0.08 || this.dadosHoje.mediaProt > 2.2)), 
        'bg-green-200': !this.fit && ((!this.dadosHoje.percP.passou && !this.dadosHoje.percP.faltou) || (this.dadosHoje.mediaProt >= 0.08 && this.dadosHoje.mediaProt <= 2.2))
      }
    },
    getFracao(quantidade) {
      if (quantidade === 0.5) return '1/2';
      if (quantidade === 0.25) return '1/4';
      return quantidade;
    },
    usuarioTest() {
      const ehRubens = this.$store.state.user.login === 'Rubens Donizete Fernandes';
      return ehRubens;
    },
    hsl_col_perc(percent, start, end) {
      var a = percent / 100,
          b = (end - start) * a,
          c = b + start;

      // Return a CSS HSL string
      return 'hsl('+c+', 100%, 40%)';
    },
    getMetaNovo() {
      const proteina = this.totais.cotas > 0 ? parseFloat(this.dadosHoje.percP.perc).toFixed(2) : parseFloat(this.dadosHoje.percP.perc).toFixed(2);
      const gordura = this.totais.cotas > 0 ? parseFloat(this.dadosHoje.percG.perc).toFixed(2) : parseFloat(this.dadosHoje.percG.perc).toFixed(2);
      const total = (proteina - gordura) * 10;

      if (total < 0) return 0;
      if (total > 100) return 100;

      return parseFloat(total).toFixed(0);
    },
    getMetaCarb() {
      //(5*kcal-40*c)/3*kcal
      let total = ((5 * this.totais.kcal) - (40 * this.totais.c)) / (3 * this.totais.kcal);

      if (parseFloat(total) > 1) total = 1;
      if (parseFloat(total) < 0) total = 0;
      return total && !isNaN(total) ? (total * 100).toFixed(0) : '0';
    },
    getPercMetaProt() {
      let total = ((this.userForm.peso * this.dadosHoje.mediaProt) / (this.dadosHoje.mediaKcal / 8)).toFixed(2);

      if (parseFloat(total) > 1) total = 1;

      return total && !isNaN(total) ? total * 100 : '0';
    },
    getIndiceProt(alimento) {
      //const indice = ((alimento.p * 2) - alimento.c);
      //const indiceAntigo = (alimento.p - (alimento.kcal / 8)); //MINHA
      const indice = alimento.c;

      if (indice > this.maiorindice) {
        this.maiorindice = indice
        this.amaiorindice = alimento
      }

      if (indice < this.menorindice) {
        this.menorindice = indice;
        this.amenorindice = alimento
      }

      console.log(this.amenorindice, this.menorindice, this.amaiorindice, this.maiorindice);
      //148
      //0
      switch (true) {
        case (indice > 20):
          return '<svg class="w-6 h-6 p-1 text-red-700" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
        case (indice > 5):
          return '<svg class="w-6 h-6 p-1 text-red-500" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
        case (indice > 3):
          return '<svg class="w-6 h-6 p-1 text-yellow-400" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
        case (indice > 2):
          return '<svg class="w-6 h-6 p-1 text-green-200" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
        case (indice > 1):
          return '<svg class="w-6 h-6 p-1 text-green-400" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
        case (indice <= 1):
          return '<svg class="w-6 h-6 p-1 text-green-600" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
        default:
          return '<svg class="w-6 h-6 p-1 text-gray-400" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>';
      }
    },
    async atualizar() {
      await this.$http.post('/form', { usuario: this.$store.state.user._id, form: this.userForm, resetPedirMedidas: true });
      this.modalpesocintura = false;
      await this.getHistorico();
    },
    ehMaior(valor, valorCompare) {
      return valor > valorCompare;
    },
    ehMenor(valor, valorCompare) {
      return valor < valorCompare;
    },
    filterBy(option, label, search) {
      return (label || '').toLowerCase().indexOf(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1
    },
    async solicitarInclusao() {
      if (this.nome) {
        await this.$http.post('/v1/alimento/sugestao', { nome: this.nome });
        this.$vToastify.success('Registrada com sucesso!', 'Solicitação');
        this.incluir = false;
        this.nome = '';
      }
    },
    traduzPlural(quantidade, nome) {
      if (quantidade > 1) {

        const vogais = ["a", "e", "i", "o", "u", "é"]
        const nomeSplited = nome.trim().split(' ');
        const lastLetra = nomeSplited[0].slice(-1);
  
        if (vogais.indexOf(lastLetra.toLocaleLowerCase()) > -1) {
          nomeSplited[0] += 's';
        } else {
          nomeSplited[0] += 'es';
        }

        if (nome.toLocaleLowerCase() === 'porção') return 'Porções';

        return nomeSplited.join(' ');
      }

      return nome;
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$store.commit('logout');
      this.$router.push({path: '/login'});
    },
    getLimite() {
       const totalCotas = parseInt(this.userForm.manha) + parseInt(this.userForm.tarde) + parseInt(this.userForm.noite);
       return totalCotas;
    },
    getCotas() {
      let totalCotas = parseInt(this.userForm.manha) + parseInt(this.userForm.tarde) + parseInt(this.userForm.noite);

      for (let i = 0; i < this.historicos.length; i++) {
        const h = this.historicos[i];
        totalCotas -= parseFloat(h.cota);
      }
      
      return totalCotas < 0 ? 0 : totalCotas;
    },
    getCotasInverso() {
      let totalCotas = 0;

      this.totais.p = 0;
      this.totais.c = 0;
      this.totais.kcal = 0;

      for (let i = 0; i < this.historicos.length; i++) {
        const h = this.historicos[i];
        totalCotas += parseFloat(h.cota);
        this.totais.p += h.p * h.quantidade;
        this.totais.c += h.c * h.quantidade;
        this.totais.kcal += h.kcal * h.quantidade;
      }
      
      return totalCotas;
    },
    ehRubens() {
      return this.$store.state.user.login === 'Rubens Donizete Fernandes';
    },
    async reset() {
      if (confirm("Tem certeza que quer reiniciar seu histórico?")) {
        await this.$http.post('/v1/usuario/reset', {});
        await this.start();
        this.modalstartday = false;
      }
    },
    async comeu() {
      const alimento = this.form.alimento;

      if (!this.ehRubens()) {
        this.data = false;
      }

      if (this.historicos.length && alimento._id === this.historicos[0].alimento) {
        this.adicionar(this.historicos[0]);
      } else {
        try {
          await this.$http.post('/v1/historico/add', { alimento, data: this.data });
          await this.getHistorico();
        } catch (error) {
          console.log(error);
          if (error.message === 'Network Error') {
            this.$vToastify.error("Houve um problema de conexão com a internet, verifique e tente novamente.", 'Erro');
          } else {
            this.$vToastify.error("Ocorreu um erro no aplicativo, feche ele e tente abrir novamente.", 'Erro');
          }
          await this.$http.post(`/error`, { error: JSON.stringify(error) });
        }
       
      }
    },
    async remover(_id) {
      await this.$http.post('/v1/historico/remove', { _id });
      await this.getHistorico();
    },
    async adicionar(_id) {
      await this.$http.post('/v1/historico/adiciona', { _id });
      await this.getHistorico();
    },
    async getHistorico() {
      if (!this.ehRubens()) {
        this.data = false;
      }

      const reqHistorico = await this.$http.post('/v1/historico', { data: this.data });
      this.historicos = reqHistorico.data;

      const reqHistorico1hora = await this.$http.post('/v1/historico', { data: this.data, fit: this.fit });
      this.historicos1hora = reqHistorico1hora.data;

      localStorage.setItem("fit", this.fit);
      const mediaReq = await this.$http.post('/v1/historico/media', { fit: this.fit });
      
      this.totalCotas = mediaReq.data.hoje.totalCotas;

      this.totais.p = 0;
      this.totais.p1hora = 0;
      this.totais.c = 0;
      this.totais.kcal = 0;
      this.totais.cotas = 0;

      for (let i = 0; i < this.historicos.length; i++) {
        const h = this.historicos[i];
        this.totais.cotas += parseFloat(h.cota);
        this.totais.p += h.p * h.quantidade;
        this.totais.c += h.c * h.quantidade;
        this.totais.kcal += h.kcal * h.quantidade;
      }

      for (let i = 0; i < this.historicos1hora.length; i++) {
        const h = this.historicos1hora[i];
        this.totais.p1hora += h.p * h.quantidade;
      }

      /*this.chartBar = this.totais.cotas > 0 ? mediaReq.data.hoje.configuration.data : mediaReq.data.ontem.configuration.data;
      this.options = this.totais.cotas > 0 ? mediaReq.data.hoje.configuration.options : mediaReq.data.ontem.configuration.options;
      this.dados = this.totais.cotas > 0 ? mediaReq.data.hoje : mediaReq.data.ontem;
      this.dadosHoje = this.totais.cotas > 0 ? mediaReq.data.hoje : mediaReq.data.ontem;
      this.media = this.totais.cotas > 0 ? mediaReq.data.hoje.media : mediaReq.data.ontem.media;*/

      this.chartBar = mediaReq.data.hoje.configuration.data;
      this.options = mediaReq.data.hoje.configuration.options;
      this.dados = mediaReq.data.hoje;
      this.dadosHoje = mediaReq.data.hoje;
      this.media = mediaReq.data.hoje.media;

      this.mensagemDoDiaMMM(mediaReq.data.hoje.usuario);
      this.mmmRecord = mediaReq.data.hoje.usuario.mmmRecord;
      //FIT

      const proteina = this.totais.cotas > 0 ? parseFloat(this.dadosHoje.percP.perc).toFixed(2) : parseFloat(this.dadosHoje.percP.perc).toFixed(2);
      const gordura = this.totais.cotas > 0 ? parseFloat(this.dadosHoje.percG.perc).toFixed(2) : parseFloat(this.dadosHoje.percG.perc).toFixed(2);
      const carboidrato = this.totais.cotas > 0 ? parseFloat(this.dadosHoje.percC.perc).toFixed(2) : parseFloat(this.dadosHoje.percC.perc).toFixed(2);

      this.chartBarFit = {
        labels: ['Proteína', 'Gordura', 'Carboidrato'],
        datasets: [
          {
            data: [proteina, gordura, carboidrato],
            borderColor: ['#66BB6A', '#FDD835', '#F44336'],
            backgroundColor: ['#66BB6A', '#FDD835', '#F44336'],
          },
        ],
        barPercentage: 0.7
      };
    },
    async mensagemDoDia(showAnyway) {
      let lcUltimoAcesso = localStorage.getItem('ultimoAcesso');
      let ultimoAcesso = moment(lcUltimoAcesso);
      let hoje = moment().startOf("day").add(3, 'hours');

      if (!lcUltimoAcesso || hoje > ultimoAcesso || showAnyway ) {
        
        if (moment().hour() < 2) return;

        const infoDias = this.dados.configuration.data.datasets[0].data;
        const infoBg = this.dados.configuration.data.datasets[0].backgroundColor;
        const totalCotaUltimoDia = infoDias[infoDias.length - 2];
        const corUltimoDia = infoBg[infoBg.length - 2];

        this.textomodalstartday = `Você está iniciando um novo ciclo, não deixe sua média passar do limite, hoje seu limite será de ${this.totalCotas} cotas`;
        this.mostrarReset = false;

        if (infoDias.length > 1) {
          this.textomodalstartday = `Parabéns, sua média ainda está abaixo do limite! Hoje seu limite será de ${this.totalCotas} cotas`;
          this.mostrarReset = false;
        }

        const mediaAjustada = this.media;//this.userForm.sexo === "10" ? this.media - 2 : this.media - 1;

        if (mediaAjustada > this.totalCotas) {
          this.textomodalstartday = 'Sua média excedeu o limite! Tente se manter abaixo do limite.';
          this.mostrarReset = false;
        } else {

          if (totalCotaUltimoDia === 0) {
            this.textomodalstartday = 'Você não anotou corretamente ontem.';
            this.mostrarReset = false;
          }

          if (corUltimoDia === '#c43100') {
            this.textomodalstartday = 'Você não anotou corretamente ontem.';
            this.mostrarReset = false;
          }
        }

        if (this.$store.state.user.pedirMedidas) {
          this.modalpesocintura = true;
        } else {
          if (this.textomodalstartday) {
            this.modalstartday = true;
          }
        }
      }

      localStorage.setItem("ultimoAcesso", hoje.toISOString());
    },
    async mensagemDoDiaMMM(usuario) {
      if (usuario.mostrarMensagem) {
        this.textomodalstartday = usuario.mensagem;
        this.mostrarReset = false;
        this.modalstartday = true;

        await this.$http.get(`/v1/usuario/mostrouMsg`);
      }
    },
    async start() {
      const user = await this.$http.get(`/v1/usuario/me`);

      if (user.data.err && user.data.err.name === 'TokenExpiredError' || user.data.data.blocked) {
        this.logout();
        return this.$router.push({ path:'/expirou' });
      }

      localStorage.setItem('user', JSON.stringify(user.data.data));
      this.$store.state.user = user.data.data;
      this.userForm = JSON.parse(this.$store.state.user.form);

      this.fit = localStorage.getItem("fit") === 'true';
      
      const reqAlimentos = await this.$http.get('alimentos?fit=' + this.fit);
      this.alimentos = reqAlimentos.data;

      await this.getHistorico();
      
      if (!this.fit) {
        //await this.mensagemDoDia();
      }
    }
  },
  async mounted() {
    try {
      await this.start();
    } catch (error) {
      this.$vToastify.error("Ocorreu um erro no aplicativo, feche ele e tente abrir novamente.", 'Erro');
      await this.$http.post(`/error`, { error: JSON.stringify(error) });
    }
  },
}
</script>